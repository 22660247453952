import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getVendorTransactions,
  getVendorCustomerTransactions,
  getVendorComboExtraTransactions,
  getVendorSoftplayExtraTransactions,
  getVendorPlaystationExtraTransactions,
  getVendorArcadeExtraTransactions,
} from "../services/transactionsApi";

interface SalesPersonState {
  loading: boolean;
  error: string | null;
  vendorTransactions: any[];
  vendorCustomerTransactions: any[];
  vendorComboExtraTransactions: any[];
  vendorSoftplayExtraTransactions: any[];
  vendorPlaystationExtraTransactions: any[];
  vendorArcadeExtraTransactions: any[];
}

const initialState = {
  loading: false,
  error: null,
  vendorTransactions: [],
  vendorCustomerTransactions: [],
  vendorComboExtraTransactions: [],
  vendorSoftplayExtraTransactions: [],
  vendorPlaystationExtraTransactions: [],
  vendorArcadeExtraTransactions: [],
} as SalesPersonState;

const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getVendorTransactions.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getVendorTransactions.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.loading = false;
          state.error = null;
          state.vendorTransactions = action.payload;
        }
      )
      .addCase(
        getVendorTransactions.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
          state.error = action.payload;
        }
      )

      .addCase(getVendorCustomerTransactions.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getVendorCustomerTransactions.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.loading = false;
          state.error = null;

          const mergedData = Object.values(
            action.payload.reduce((acc: any, current: any) => {
              if (acc[current.billNumber]) {
                acc[current.billNumber].totalAmount += current.totalAmount;
                acc[current.billNumber].discountedAmount +=
                  current.discountedAmount;
                acc[current.billNumber].totalAmountPaid +=
                  current.totalAmountPaid;
              } else {
                acc[current.billNumber] = { ...current };
              }

              return acc;
            }, {})
          );

          state.vendorCustomerTransactions = mergedData;
        }
      )
      .addCase(
        getVendorCustomerTransactions.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
          state.error = action.payload;
        }
      )

      .addCase(getVendorComboExtraTransactions.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getVendorComboExtraTransactions.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.loading = false;
          state.error = null;
          state.vendorComboExtraTransactions = action.payload;
        }
      )
      .addCase(
        getVendorComboExtraTransactions.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
          state.error = action.payload;
        }
      )

      .addCase(getVendorSoftplayExtraTransactions.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getVendorSoftplayExtraTransactions.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.loading = false;
          state.error = null;
          state.vendorSoftplayExtraTransactions = action.payload;
        }
      )
      .addCase(
        getVendorSoftplayExtraTransactions.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
          state.error = action.payload;
        }
      )

      .addCase(
        getVendorPlaystationExtraTransactions.pending,
        (state, action) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addCase(
        getVendorPlaystationExtraTransactions.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.loading = false;
          state.error = null;
          state.vendorPlaystationExtraTransactions = action.payload;
        }
      )
      .addCase(
        getVendorPlaystationExtraTransactions.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
          state.error = action.payload;
        }
      )

      .addCase(getVendorArcadeExtraTransactions.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getVendorArcadeExtraTransactions.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.loading = false;
          state.error = null;
          state.vendorArcadeExtraTransactions = action.payload;
        }
      )
      .addCase(
        getVendorArcadeExtraTransactions.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
          state.error = action.payload;
        }
      );
  },
});

export default transactionSlice.reducer;
